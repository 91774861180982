import { TYPE_BROADCAST } from './types'
/**
 * Change text only, do not change text inside {{ }}
 */
export const customText = {
  [TYPE_BROADCAST.CUSTOM_ANNOUNCEMENT]: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}* 

Mohon klik link website announcement kami dibawah ini sebagai detail pemberitahuan:
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami dan tanpa mengurangi rasa hormat, kami berniat ingin mengumumkan kepada Bapak/Ibu/Saudara/i mengenai acara pernikahan kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}* 
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}*.

Berkaitan dengan salah satu pemberlakuan protokol kesehatan, yakni pembatasan jumlah tamu, kami memohon maaf jika belum bisa mengundang Bapak/Ibu/Saudara/i untuk hadir secara langsung. 

Meski demikian, di masa pandemi saat ini hal terpenting adalah sama-sama saling menjaga agar kita semua selalu sehat dan senantiasa dalam lindungan Tuhan.

Terima kasih atas segala doa restu yang telah diberikan kepada kami. 
Mohon pengertiannya, terima kasih.

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*
  `,
  [TYPE_BROADCAST.CUSTOM_INVITATION]: `Yth. Bapak/Ibu/Saudara/i,  
*{{GUEST_NAME}}*

*Tanpa mengurangi rasa hormat, mohon klik link dibawah ini untuk mendapatkan informasi lengkap mengenai acara pernikahan kami:*
{{LINK}}

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami, pada hari ini kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dan turut memberikan doa secara langsung dalam acara kami: 

*{{BOY_NAME}}*
_Putra dari Bapak {{BOY_FATHER_NAME}} dan Ibu {{BOY_MOTHER_NAME}}_
  & 
*{{GIRL_NAME}}*
_Putri dari Bapak {{GIRL_FATHER_NAME}} dan Ibu {{GIRL_MOTHER_NAME}}_

Acara pernikahan kami akan dilaksanakan pada hari *{{BROADCAST_WEDDING_DAY}}* di {{BROADCAST_WEDDING_LOCATION}}.

Merupakan suatu kehormatan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam acara pernikahan kami. 

Mohon pengertiannya untuk segala protokol kesehatan yang berlaku. Semoga kita semua selalu dalam keadaan sehat. 

Sampai jumpa di acara pernikahan kami!

_Kami yang berbahagia,_
*{{BOY_NAME_SHORT}}, {{GIRL_NAME_SHORT}} & keluarga*
    `,
  CUSTOM_BROADCAST_WITH_QR: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}*

*Tanpa mengurangi rasa hormat, mohon klik link dibawah ini untuk mendapatkan informasi lengkap mengenai acara pernikahan kami:*
{{LINK}}
_*) Alasan keamanan WhatsApp, jika link tidak clickable (tidak berwarna biru), silakan untuk coba klik "Continue / Lanjutkan", balas "YA", atau simpan nomor ini untuk dapat membuka link._

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami, pada hari ini kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dan turut memberikan doa secara langsung dalam acara kami: 

*Stela Natalia Mulia*
_Putri dari Bapak Marthinus Lumalessil dan Ibu Almh Dra Tri Lestari Wahyuningsih_
& 
*Lucia Bara Susanto* 
_Putra dari Bapak Budianto dan Ibu Nurul Chotimah_

Acara pernikahan kami akan dilaksanakan pada hari *Minggu, 10 September 2023* di Waterboom Lippo Cikarang.

Merupakan suatu kehormatan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam acara pernikahan kami. 

Mohon pengertiannya untuk segala protokol kesehatan yang berlaku. Semoga kita semua selalu dalam keadaan sehat. 

*Berkaitan dengan keberlangsungan acara, dimohon kerjasamanya untuk menunjukkan QR Code kehadiran pada petugas dilokasi acara. Jika kesulitan jangan ragu untuk menghubungi tim kami yang ada di area penerimaan tamu*.

Sampai jumpa di acara pernikahan kami!

_Kami yang berbahagia,_
*Stela, Bara & keluarga*
  `,
  CUSTOM_BROADCAST_WITHOUT_QR: `Yth. Bapak/Ibu/Saudara/i, 
*{{GUEST_NAME}}*

*Tanpa mengurangi rasa hormat, mohon klik link dibawah ini untuk mendapatkan informasi lengkap mengenai acara pernikahan kami:*
{{LINK}}
_*) Alasan keamanan WhatsApp, jika link tidak clickable (tidak berwarna biru), silakan untuk coba klik "Continue / Lanjutkan", balas "YA", atau simpan nomor ini untuk dapat membuka link._

Bersama rahmat dan berkat Tuhan yang telah menyatukan kami, pada hari ini kami berniat ingin mengundang Bapak/Ibu/Saudara/i untuk hadir dan turut memberikan doa secara langsung dalam acara kami: 

*Stela Natalia Mulia*
_Putri dari Bapak Marthinus Lumalessil dan Ibu Almh Dra Tri Lestari Wahyuningsih_
& 
*Lucia Bara Susanto* 
_Putra dari Bapak Budianto dan Ibu Nurul Chotimah_

Acara pernikahan kami akan dilaksanakan pada hari *Minggu, 10 September 2023* di Waterboom Lippo Cikarang.

Merupakan suatu kehormatan bagi kami apabila Bapak/Ibu/Saudara/i berkenan hadir dan mendoakan secara langsung dalam acara pernikahan kami. 

Mohon pengertiannya untuk segala protokol kesehatan yang berlaku. Semoga kita semua selalu dalam keadaan sehat. 

*Berkaitan dengan ketertiban keberlangsungan acara, dimohon kerjasamanya untuk melakukan konfirmasi kehadiran pada website undangan di atas yang nantinya akan mendapatkan QR code yang dapat disimpan sebagai konfirmasi kedatangan di lokasi acara*.

Sampai jumpa di acara pernikahan kami!

_Kami yang berbahagia,_
*Stela, Bara & keluarga*
  `
}